import React from 'react';
import classnames from "classnames";
import {useAuth} from "../../../../auth/useAuth";
import {useTranslation} from "react-i18next";
import styles from './Settings.module.css';

type SettingsProps = {
    isOpen: boolean;
    onLogoutClick: () => void;
}

function Settings({isOpen, onLogoutClick}: SettingsProps) {
    let auth = useAuth();
    const {t} = useTranslation('common');

    const onClick = () => {
        auth.signout();
        onLogoutClick();
    }

    return (
        <div
            className={classnames(
                styles.content,
                {[styles.open]: isOpen}
            )}
            id="settings"
            aria-labelledby="settings-button"
        >
            <button
                className={styles.button}
                onClick={onClick}
            >
                {t('settings.logout')}
            </button>
        </div>
    );
};

export default React.memo(Settings);