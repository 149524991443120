import React, {useContext, useEffect} from 'react';
import Highlighter from "react-highlight-words";
import striptags from "striptags";
import {useTranslation} from "react-i18next";
import {SectionViewModel} from "../../../api/api";
import SearchResultLink from "./SearchResultLink";
import {searchContext} from "../../Layout/Header/Header";
import styles from './SearchResults.module.css';

type SearchResultsProps = {
    searchHits: SectionViewModel[];
    searchTerm: string;
    isOpen: boolean;
}

function SearchResults({searchHits, searchTerm}: SearchResultsProps) {
    const {isSearchOpen} = useContext(searchContext);
    const {t} = useTranslation('common');

    useEffect(() => {
        if (isSearchOpen) {
            document.body.style.position = 'fixed';
            document.body.style.overflowY = 'scroll';
        } else {
            document.body.style.position = 'static';
            document.body.style.overflowY = 'auto';
        }
    }, [isSearchOpen])

    return (
        <div className={styles.content}>
            {
                searchTerm.length > 1 && searchHits.length > 0 && <span
                    className={styles.resultsTitle}
                >
                    {t('search.result')} ({searchHits.length})
                </span>
            }
            {searchHits.length > 0
                ? <ul className={styles.list}>
                    {
                        searchHits?.map((section: any) => {
                            const text = striptags(`${section.content || ''}\n${section.customContent || ''}`);
                            
                            const e = document.createElement('textarea');
                            e.innerHTML = text;
                            
                            return <li key={section.id} className={styles.listItem}>
                                <h3>{section.heading}</h3>
                                {<p>
                                    <Highlighter
                                        highlightClassName={styles.highlighted}
                                        searchWords={[searchTerm]}
                                        autoEscape={true}
                                        textToHighlight={e.childNodes[0]?.nodeValue ?? ''}
                                    />
                                </p>}
                                <SearchResultLink 
                                    section={section}
                                />
                            </li>
                        })
                    }
                </ul>
                : searchTerm.length > 2
                    ? <p className={styles.info}>
                        {t('search.noResult')}: <strong>{searchTerm}</strong>
                    </p>
                    : null

            }
        </div>
    );
}

export default SearchResults;
