import React from "react";
import {Helmet} from "react-helmet";
import {HandbookInfoViewModel} from "../../api/api";

function Head({handbookInfo}: { handbookInfo?: HandbookInfoViewModel }) {
    return (
        <Helmet htmlAttributes={{
            lang: handbookInfo?.language,
        }}
        >
            <title>{handbookInfo?.title}</title>
        </ Helmet>
    );
}

export default Head;