import React from "react";
import {
    Router,
    Switch,
    Redirect,
} from "react-router-dom";
import {ProvideAuth} from "./auth/ProvideAuth";
import PrivateRoute from "./auth/PrivateRoute";
import LoginPage from "./pages/Login/LoginPage";
import HandbookPage from './pages/Handbook/HandbookPage';
import ChapterPage from "./pages/Chapter/ChapterPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import PublicRoute from "./auth/PublicRoute";
import {createBrowserHistory} from "history";
import AppInsightsProvider from "./components/AppInsights/AppInsightsProvider";

function App() {

    const browserHistory = createBrowserHistory({ basename: process.env.PUBLIC_URL });
    
    return (
        <div className="App">
            <AppInsightsProvider history={browserHistory}>
                <ProvideAuth>
                    <Router history={browserHistory}>
                        <Switch>
                            <PrivateRoute path={`/handbok/:handbookId/chapters/:chapterId`}>
                                <ChapterPage/>
                            </PrivateRoute>
                            <PublicRoute path={`/handbok/:handbookId/login`} exact={true}>
                                <LoginPage/>
                            </PublicRoute>
                            <PrivateRoute path={`/handbok/:handbookId`}>
                                <HandbookPage/>
                            </PrivateRoute>
                            <PublicRoute path={`/404`}>
                                <ErrorPage
                                    errorCode={404}
                                />
                            </PublicRoute>
                            <PublicRoute path={`/`}>
                                <Redirect to={{
                                    pathname: `/404`,
                                }}/>
                            </PublicRoute>
                        </Switch>
                    </Router>
                </ProvideAuth>
            </AppInsightsProvider>
        </div>
    );
}

export default App;