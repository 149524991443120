import React from "react";
import classnames from "classnames";
import {useHistory, useLocation} from "react-router-dom";
import {useParams} from "react-router";
import {SectionViewModel} from "../../../api/api";
import {ThemeColors} from "../../../theme/useTheme";
import styles from './AccordionSubItem.module.css';

type SideMenuSubItemProps = {
    section: SectionViewModel;
    themeColors: ThemeColors;
    chapterId: string | undefined;
}

function AccordionSubItem({section, themeColors, chapterId}: SideMenuSubItemProps) {
    const {hash} = useLocation();
    const history = useHistory();
    const {handbookId} = useParams<{ handbookId: string }>();
    const isCurrent = `#${section.softLink}` === hash;

    const onClick = () => {
        history.push(`/handbok/${handbookId}/chapters/${chapterId}#${section.softLink}`);
    }

    return (
        <li className={styles.content} role="none">
            {isCurrent && <span
                className={styles.circle}
                style={{backgroundColor: themeColors.dark}}
                role="none"
            />}
            <button
                className={classnames(
                    styles.button,
                    {[styles.current]: isCurrent}
                )}
                onClick={onClick}
                role="menuitem"
            >
                {section.heading}
            </button>
        </li>
    );
}

export default AccordionSubItem;