import {useContext} from "react";
import {ThemeName, themeNames} from "./themeNames";
import {HandbookIdContext, handbookIdContext} from "../api/ProvideHandbookInfo";

export function getColors(color: ThemeName) {
    return themeNames[color] ?? themeNames.default
}

export type ThemeColors = { dark: string, light: string };

type UseTheme = {
    themeName: string;
    themeColors: ThemeColors;
}

export function useTheme(): UseTheme {
    const infoContext = useContext<HandbookIdContext>(handbookIdContext) ?? {};

    function getThemeName(): ThemeName {
        const themeColor = (infoContext.handbookInfo?.themeColor as ThemeName)
        return themeColor ?? "default";
    }

    function getThemeColors(): { dark: string, light: string } {
        const themeName = getThemeName();
        const themeColors = getColors(themeName);
        document.documentElement.style.setProperty('--theme-color-dark', themeColors.dark);
        document.documentElement.style.setProperty('--theme-color-light', themeColors.light);
        document.documentElement.style.setProperty('--theme-color-button', themeColors.button ?? "#FCE385");
        document.documentElement.style.setProperty('--theme-color-button-text', themeColors.buttonText ?? "#222222");
        return themeColors;
    }

    return {
        themeName: getThemeName(),
        themeColors: getThemeColors()
    }
}

