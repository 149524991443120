import React from "react";
import {useAuth} from "../../../../auth/useAuth";
import {useTranslation} from "react-i18next";
import Avatar from "./Avatar";
import styles from './Account.module.css';

type AccountProps = {
    isOpen: boolean;
    onClick: () => void;
}

function Account({isOpen, onClick}: AccountProps) {
    let auth = useAuth();
    const {t} = useTranslation('common');

    const onToggle = () => {
        onClick();
    }

    return <div className={styles.content}>
        <button
            id="settings-button"
            aria-expanded={isOpen}
            aria-label={isOpen ? t('menu.collapse') : t('menu.expand')}
            onClick={onToggle}
            className={styles.button}
        >
            <span hidden>{t('settings.settings')}</span>
        </button>
        <Avatar
            name={auth.user ?? 'DH'}
        />
    </div>
}

export default Account