import React, {createContext, useEffect, useRef} from "react";
import {useParams} from "react-router";
import {useHandbookData} from "../../api/useHandbookData";
import {useChapterData} from "../../api/useChapterData";
import Banner from "../../components/Chapters/Banner/Banner";
import Chapter from "../../components/Chapters/Chapter";
import Footer from "../../components/Layout/Footer/Footer";
import Header, {HeaderType} from "../../components/Layout/Header/Header";
import ScrollTo from "../../components/ScrollTo/ScrollTo";
import BackToTop from "../../components/Chapters/BackToTop/BackToTop";
import BottomNav from "../../components/Layout/BottomNav/BottomNav";
import styles from "./ChapterPage.module.css";

export const chapterContext = createContext(undefined as any);

function ChapterPage() {
    const {chapterId} = useParams<any>();
    const {handbook} = useHandbookData();
    const {chapter, chapter: {sections = []}} = useChapterData(chapterId);
    const sectionRefs = useRef([]);

    useEffect(() => {
        sectionRefs.current = sectionRefs.current.slice(0, sections?.length);
    }, [sections]);

    return (
        <chapterContext.Provider value={sectionRefs}>
            <ScrollTo/>
            <Header
                handbook={handbook}
                headerType={HeaderType.CHAPTER}
            />
            <main className={styles.content}>
                <Banner
                    imagePath={chapter.coverImageAzurePath ?? ''}
                    imageAlt={chapter.coverImageAzureAlt ?? ''}
                    heading={chapter.heading}
                />
                <Chapter
                    chapterId={chapterId}
                />
                <BottomNav/>
                <BackToTop/>
            </main>
            <Footer/>
        </chapterContext.Provider>
    );
}

export default ChapterPage;