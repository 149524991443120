import {useTranslation} from "react-i18next";
import styles from './Spinner.module.css';

function Spinner() {
    const {t} = useTranslation('common');
    return (
        <div className={styles.content}>
            <span hidden>{t('loading')}</span>
            <div className={styles.spinner}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

export default Spinner;