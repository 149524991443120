import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {searchContext} from "../../Layout/Header/Header";
import MagnifierGlass from "../../../static/images/icons/magnifier.svg";
import icons from "../../Hero/Icons.module.css";
import styles from './SearchInput.module.css';

type SearchInputProps = {
    onSearch: (searchTerm: string) => void;
}

function SearchInput({onSearch}: SearchInputProps) {
    const {t} = useTranslation('common');
    const {isSearchOpen} = useContext(searchContext);
    const [inputValue, setInputValue] = useState('');

    const onTextChange = (event: any) => {
        const searchTerm = event.target.value || '';
        setInputValue(searchTerm);
        onSearch(searchTerm);
    };

    const onButtonSearchClick = (evt: any) => {
        evt.preventDefault();
        const searchTerm = inputValue || ""
        onSearch(searchTerm);
    }

    const onReset = () => {
        setInputValue('');
    }

    useEffect(() => {
        if (!isSearchOpen) onReset();
    }, [isSearchOpen])

    return (
        <form
            className={styles.content}
            onSubmit={onButtonSearchClick}
            role="search"
            id="search-input-form-1"
            aria-labelledby="search-input-button-1"
        >
            <img src={MagnifierGlass}
                 role="presentation"
                 className={icons.search}
                 alt="Søk"
                 loading="lazy"
            />
            <label htmlFor="search-input" className="visually-hidden">Søk</label>
            <input
                className={styles.input}
                id="search-input"
                type="search"
                placeholder={t('search.placeholder')}
                value={inputValue}
                onChange={onTextChange}
            />
        </form>
    );
}

export default SearchInput;
