import React, {useContext} from "react";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import {searchContext} from "../../Layout/Header/Header";
import styles from './SearchResultLink.module.css';

type SearchResultLinkProps = {
    section: any;
}

function SearchResultLink({section}: SearchResultLinkProps) {
    const history = useHistory();
    const {handbookId} = useParams<{ handbookId: string }>();
    const {onSearchClose} = useContext(searchContext);

    const onClick = (e: any) => {
        e.preventDefault();
        onSearchClose();
        history.push(`/handbok/${handbookId}/chapters/${section.parent}#${section.softLink}`);
    }

    return (
        <p className={styles.content}>
            <a
                href={`/handbok/${handbookId}/chapters/${section.parent}#${section.softLink}`}
                onClick={onClick}
            >
                {`/handbok/${handbookId}/chapters/${section.parent}#${section.softLink}`}
            </a>
        </p>
    );
}

export default SearchResultLink;