import React, {ReactNode} from "react";
import {History} from "history";
import {AppInsightsContext, ReactPlugin} from "@microsoft/applicationinsights-react-js";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";

type AppInsightsProviderProps = {
    children?: ReactNode;
    history: History;
}

const initAppInsights = (history: History) => {
    const reactPlugin = new ReactPlugin();
    const ai = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.REACT_APP_AI_INSTRUMENTATION_KEY,
            extensions: [ reactPlugin ],
            extensionConfig: {
                [reactPlugin.identifier]: { history: history }
            },
            enableAutoRouteTracking: true,
            disableFetchTracking: false,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            enableUnhandledPromiseRejectionTracking: true
        }
    });
    ai.loadAppInsights();
    ai.trackPageView({
        name: history.location.pathname,
        uri: history.location.pathname
    });
    ai.trackEvent({
        name: 'AppStarted',
        properties: { 'AppName': 'DigitalHandbookEditor' }
    });

    return {appInsights: ai.appInsights, reactPlugin};
}

function AppInsightsProvider({children, history}: AppInsightsProviderProps) {

    const {reactPlugin} = initAppInsights(history)

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            {children}
        </AppInsightsContext.Provider>
    );
}

export default AppInsightsProvider;