import {AuthenticateUserClient, RefreshUserTokenClient} from "../api/api";

const authClient = new AuthenticateUserClient(window.location.origin);
const refreshClient = new RefreshUserTokenClient(window.location.origin);

const ACCESS_TOKEN = 'access_token';
const USERNAME = 'username';
const REFRESH_TOKEN = 'refresh_token';

export const retrieveToken = (type: string = ACCESS_TOKEN) => localStorage.getItem(type);
export const saveToken = (token: string, type: string = ACCESS_TOKEN) => localStorage.setItem(type, token)
export const clearToken = (type: string = ACCESS_TOKEN) => localStorage.removeItem(type);

export const retrieveUserName = (type: string = USERNAME) => localStorage.getItem(type);
export const saveUserName = (username: string, type: string = USERNAME) => localStorage.setItem(type, username)
export const clearUserName = (type: string = USERNAME) => localStorage.removeItem(type);

export const shouldRefreshToken = (error: any) =>
    error.response.status === 401 &&
    error.body.message === 'Authorization has been denied for this request.';

export const refreshToken = async () => {
    try {

        const refreshToken = retrieveToken(REFRESH_TOKEN);
        const accessToken = retrieveToken();

        if (refreshToken && accessToken) {
            const result = await refreshClient.post({
                accessToken,
                refreshToken
            });

            saveToken(result.accessToken.token, ACCESS_TOKEN);
            saveToken(result.refreshToken.token, REFRESH_TOKEN);
        }
    } catch (e) {

        clearToken(ACCESS_TOKEN);
        clearToken(REFRESH_TOKEN);
        
        const loc = window.location;
        window.location.href = `${loc.origin}${loc.pathname}${loc.pathname.endsWith('/login') ? '' : '/login'}${loc.search}`;
    }
}

export const jwtAuth = {
    async signin(userName: string, password: string, handbookUrl: string, requestedUrl: string) {
        const result = await authClient.post({
            password,
            userName,
            handbookUrl,
            requestedUrl
        });

        saveToken(result.accessToken.token, ACCESS_TOKEN);
        saveToken(result.refreshToken.token, REFRESH_TOKEN);
        saveUserName(userName, USERNAME);
    },
    async signout() {
        clearToken(ACCESS_TOKEN);
        clearToken(REFRESH_TOKEN);
        clearUserName(USERNAME);
    }
};