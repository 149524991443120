import React from 'react';
import {useHandbookData} from "../../api/useHandbookData";
import Header, {HeaderType} from '../../components/Layout/Header/Header';
import Hero from '../../components/Hero/Hero';
import ScrollTop from "../../components/ScrollTo/ScrollTop";
import ChaptersList from '../../components/Chapters/List/ChaptersList';
import Footer from '../../components/Layout/Footer/Footer';
import {useTheme} from "../../theme/useTheme";
import styles from "./HandbookPage.module.css";

export interface ParamTypes {
    handbookId: string;
}

function HandbookPage() {
    const {handbook, companyName, branchName} = useHandbookData();
    const {themeColors} = useTheme();

    return (
        <>
            <ScrollTop/>
            <Header
                handbook={handbook}
                headerType={HeaderType.HANDBOOK}
                logoBase64={handbook.logo || ''}
            />
            <main className={styles.content}>
                <Hero
                    title={handbook.title}
                    companyName={companyName}
                    branchName={branchName}
                    coverPhotoPath={handbook.coverImageAzurePath}
                    coverPhotoAlt={handbook.coverImageAzureAlt}
                />
                <ChaptersList
                    chapters={handbook.chapters}
                    updated={handbook.publishedDate}
                    themeColors={themeColors}
                />
            </main>
            <Footer/>
        </>
    );
}

export default HandbookPage;
