import React from 'react';
import {useParams} from "react-router";
import {Link} from "react-router-dom";
import classnames from 'classnames';
import {useTranslation} from "react-i18next";
import useIsMobile from "../../../../helpers/useIsMobile";
import {HandbookViewModel} from "../../../../api/api";
import styles from './Nav.module.css';
import Settings from "../Account/Settings";
import Avatar from "../Account/Avatar";


type NavProps = {
    handbook: HandbookViewModel
    isOpen: boolean;
    onMenuClose: () => void;
}

function Nav({handbook, isOpen, onMenuClose}: NavProps) {
    const {t} = useTranslation('common');
    const chapters = handbook.chapters ?? [];
    const {handbookId} = useParams<{ handbookId: string }>();
    const {isMobile} = useIsMobile();

    const onBlur = (event: any, index: number) => {
        if (index + 1 === chapters.length && event.keyCode === 9) {
            onMenuClose();
        }
    }

    return (
        <div
            className={classnames(
                styles.content,
                {[styles.open]: isOpen}
            )}
        >
            {isOpen && <nav>
                {
                    isMobile && <div className={styles.accountInfo}>
                        <Avatar name={handbook?.userName ?? 'DH'}/>
                        <Settings isOpen={isOpen} onLogoutClick={onMenuClose}/>
                    </div>
                }
                <h2 className={styles.heading}>
                    {t('menu.info')}
                </h2>
                <ul
                    role="menu"
                    id="menu-list-1"
                    aria-labelledby="menu-button-1"
                >
                    {
                        chapters.map((chapter, index) => <li key={`${chapter.softLink}-${index}`} role="none">
                            <Link
                                to={{
                                    pathname: `/handbok/${handbookId}/chapters/${chapter.softLink}`,
                                }}
                                onClick={onMenuClose}
                                onKeyUp={(e) => onBlur(e, index)}
                                role="menuitem"
                            >
                                {chapter.heading}
                            </Link>
                        </li>)
                    }
                </ul>
            </nav>}
        </div>
    );
}

export default Nav;
