import {useEffect, useState} from "react";
import {debounce} from "../../../helpers/debounce";

function useSticky() {
    const [isSticky, setSticky] = useState(false);
    const [isUp, setIsUp] = useState(false);
    
    useEffect(() => {
        let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

        const handleScroll = () => {
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            const scrollDown = scrollPosition > lastScrollTop && scrollPosition > 45;

            if (window.scrollY > 45 && scrollDown) setSticky(true);
            if (window.scrollY < 90 && !scrollDown) setSticky(false);

            if (scrollDown) {
                setIsUp(true);
            } else {
                setIsUp(false);
            }
            lastScrollTop = scrollPosition <= 0 ? 0 : scrollPosition;
        }
        const debounced = debounce(handleScroll);
        window.addEventListener("scroll", debounced);
        
        return () => {
            window.removeEventListener("scroll", debounced);
        }
    }, [])

    return {isSticky, isUp}
}

export default useSticky;