import React from "react";
import {useTranslation} from "react-i18next";
import {ChapterViewModel} from "../../../api/api";
import {ThemeColors} from "../../../theme/useTheme";
import ChapterListItem from '../ListItem/ChapterListItem';
import styles from './ChaptersList.module.css';

type ChaptersListProps = {
    chapters?: ChapterViewModel[];
    updated?: string;
    themeColors: ThemeColors
}

function ChaptersList({chapters = [], updated, themeColors}: ChaptersListProps) {
    const {t} = useTranslation('common');

    return (
        <div className={styles.wrapper} id="main">
            <div className={styles.updated}>
                <span>
                    {t('home.chaptersList.lastUpdated')}: {updated}
                </span>
            </div>
            <ul className={styles.content}>
                {
                    chapters.map((chapter, index) =>
                        <ChapterListItem
                            key={`${chapter.softLink}-${index}`}
                            heading={chapter.heading ?? ''}
                            id={chapter.softLink ?? ''}
                            sections={chapter.sections ?? []}
                            imageAzurePath={chapter.coverImageAzurePath ?? ''}
                            imageAzureAlt={chapter.coverImageAzureAlt ?? ''}
                            themeColors={themeColors}
                        />)
                }
            </ul>
        </div>
    );
}

export default ChaptersList;
