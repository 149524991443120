import {useContext, useState} from "react";
import {authContext} from "./ProvideAuth";
import {jwtAuth} from "./jwtAuth";

type UseProvideAuth = {
    user: string | null;
    isAuthenticated: boolean;
    signin: any;
    signout: any;
}

export function useProvideAuth(): UseProvideAuth {
    const initUser = useContext(authContext)?.user;
    const [user, setUser] = useState<string | null>(initUser);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    const signin = async (userName: string, password: string, handbookUrl: string, requestedUrl: string) => {
        await jwtAuth.signin(userName, password, handbookUrl, requestedUrl);
        setUser(userName);
        setIsAuthenticated(true);
    };

    const signout = async () => {
        jwtAuth.signout();
        setUser(null);
        setIsAuthenticated(false);
    };

    return {
        isAuthenticated,
        user,
        signin,
        signout
    };
}

export function useAuth(): UseProvideAuth {
    return useContext(authContext);
}