import classnames from 'classnames';
import styles from './Button.module.css';

type ButtonProps = {
    color: string;
    text: string;
    cssClass?: string;
    onClick: () => void;
}

export default function Button({color, text, cssClass, onClick}: ButtonProps) {
    return (
        <button
            onClick={onClick}
            className={classnames(
                cssClass,
                styles.content,
            )}
            style={{backgroundColor: color}}
        >
            {text}
        </button>
    );
}