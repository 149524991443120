import React from "react";

export default function useIsMobile() {
    const [isMobile, setMobile] = React.useState(false);

    React.useEffect(() => {
        function handleResize() {
            const mediaQuery = window.matchMedia('(max-width: 767px)');
            setMobile(mediaQuery.matches);
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    return {isMobile};
}