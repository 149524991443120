import React from 'react';
import {Link} from 'react-router-dom';
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {useHandbookData} from "../../../../api/useHandbookData";
import {ParamTypes} from '../../../../pages/Handbook/HandbookPage';
import styles from './Logo.module.css';

type LogoProps = {
    logoBase64: string
}

function Logo({logoBase64}: LogoProps) {
    const {t} = useTranslation('common');
    const {handbookId} = useParams<ParamTypes>();
    const logo = logoBase64.slice(1, -1); /* removes unnecessary quotes */
    const {companyName} = useHandbookData();

    return (
        <Link to={`/handbok/${handbookId}`} className={styles.content}>
            <img
                src={logo}
                alt={t('header.logo.alt', {companyName: companyName})}
            />
        </Link>
    );
}

export default Logo;
