import React, {useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useParams} from "react-router";
import {useAuth} from "../../auth/useAuth";
import {useTranslation} from "react-i18next";
import {LocationState} from "../../pages/Login/LoginPage";
import styles from "./LoginForm.module.css"
import queryString from 'query-string';

function LoginForm() {
    let history = useHistory();
    let location = useLocation<LocationState>();
    let auth = useAuth();
    const {handbookId} = useParams<{ handbookId: string }>();
    const {t} = useTranslation('common');
    const [status, setStatus] = useState('');
    let {from} = location.state || {from: {pathname: `/handbok/${handbookId}`}};
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const triedLoggingIn = useRef(false);
    const parsedSearch = queryString.parse(location.search);

    useEffect(() => {
        if (parsedSearch && parsedSearch.id && parsedSearch.pw) {
            setUserName(parsedSearch.id as string);
            setPassword(parsedSearch.pw as string);
        }
    }, []);

    useEffect(() => {
        if ((username && password) && !triedLoggingIn.current && (parsedSearch.id && parsedSearch.pw)) {
            triedLoggingIn.current = true;
            login();
        }
    }, [username, password]);

    let login = async (e?: any) => {
        e?.preventDefault();
        if (!(username && password)) return;
        if (handbookId) {
            const requestedUrl = `/handbok/${handbookId}`;
            try {
                await auth.signin(username, password, handbookId, requestedUrl);
                history.replace(from);
            } catch (e:any) {
                setStatus(t('login.error'));
                if (e.status === 400) {
                    setStatus(t('login.error'));
                } else {
                    history.replace({pathname: "/404", search: "", hash: "", state: undefined});
                }
            }
        } else {
            history.replace({pathname: "/404", search: "", hash: "", state: undefined});
        }
    };

    return <form className={styles.form} method="post">
        <h3>{t('login.submit')}</h3>
        <ul>
            <li>
                <label htmlFor="username">
                    {t('login.username')}
                </label>
                <input
                    id="username"
                    type="text"
                    name="username"
                    autoComplete="username"
                    required
                    onChange={e => setUserName(e.target.value)}
                />
            </li>
            <li>
                <label htmlFor="password">
                    {t('login.password')}
                </label>
                <input
                    id="password"
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    required
                    onChange={e => setPassword(e.target.value)}
                />
            </li>
        </ul>

        <button
            type="submit"
            className={styles.button}
            onClick={login}
        >
            {t('login.submit')}
        </button>

        {status && <p className={styles.status}>
            {status}
        </p>}
    </form>
}

export default LoginForm;