import {useContext, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {chapterContext} from "../../pages/Chapter/ChapterPage";

export default function ScrollTo() {
    const {pathname, hash} = useLocation();
    const refs = useContext(chapterContext);

    useEffect(() => {
        if (!hash) {
            window.scrollTo(0, 0);
            return;
        }

        const target = refs.current.find((el: HTMLElement) => `#${el.id}` === hash);
        if (!target) return;

        const pos = target.style.position;
        const top = target.style.top;
        target.style.position = 'relative';
        target.style.top = '-100px';
        target.scrollIntoView({behavior: 'smooth', block: 'start'});
        target.style.top = top;
        target.style.position = pos;

    }, [pathname, hash, refs?.current]);

    return null;
}