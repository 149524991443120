import React from 'react';
import styles from './Avatar.module.css';


function getInitials(name: string): string {
    const [firstName, lastName] = name.split(' ');

    if (firstName && lastName) {
        return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
    }

    return firstName.charAt(0).toUpperCase();
}

type AvatarProps = {
    name: string;
}

const Avatar = ({name}: AvatarProps) => {
    
    return (
        <div className={styles.content}>
            <span>{getInitials(name)}</span>
        </div>
    );
};

export default React.memo(Avatar);