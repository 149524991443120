import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {handbookIdContext} from "../../api/ProvideHandbookInfo";
import styles from "./ErrorPage.module.css";

type ErrorPageProps = {
    errorCode?: number;
}

function ErrorPage({errorCode = 404}: ErrorPageProps) {

    const {t} = useTranslation('common');
    const {theme} = useContext(handbookIdContext);

    return (<div className={styles.content}>
            <div className={styles.background}>
                <div className={styles.light} style={{backgroundColor: theme?.light}}>
                </div>
                <div className={styles.dark} style={{backgroundColor: theme?.dark}}>
                    <div className={styles.errorCode}>
                        <p>{errorCode}</p>
                    </div>

                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.text}>
                    <h1 className={styles.heading}>{t('error.message')}</h1>
                    <p>{t('error.info')}</p>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;