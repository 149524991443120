import React from "react";
import classNames from "classnames";
import styles from './Section.module.css';

type SectionLinksProps = {
    title: string;
    url: string;
    isDocument?: boolean;
}

function SectionLinks({title, url, isDocument = false}: SectionLinksProps) {
    return (
        <a
            className={classNames(styles.agreementLink, {
                [styles.document]: isDocument
            })}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
        >
            {title}
        </a>
    );
}

export default SectionLinks;