import React from 'react';
import {useHandbookData} from "../../../api/useHandbookData";
import Accordion from '../Accordion/Accordion';
import styles from './SideMenu.module.css';

function SideMenu() {
    const {chapters} = useHandbookData();

    return (
        <div className={styles.content}>
            <Accordion
                chapters={chapters}
            />
        </div>
    );
}

export default SideMenu;