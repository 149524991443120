import React, {useContext} from "react";
import LoginForm from "../../components/LoginForm/LoginForm";
import classNames from "classnames";
import {handbookIdContext} from "../../api/ProvideHandbookInfo";
import styles from "./LoginPage.module.css";
import {useTheme} from "../../theme/useTheme";
import Logo from "../../components/Layout/Header/Logo/Logo";

export interface LocationState {
    from: {
        pathname: string;
    };
}

function LoginPage() {
    const {handbookInfo} = useContext(handbookIdContext);
    const {themeColors} = useTheme();

    return (<div className={styles.content}>
            <div className={styles.background}>
                <div className={styles.light} style={{backgroundColor: themeColors?.dark}}>
                </div>
                <div className={styles.dark} style={{backgroundColor: themeColors?.light}}>
                    {handbookInfo?.coverImageAzurePath &&
                    <img src={handbookInfo?.coverImageAzurePath} alt={handbookInfo?.coverImageAzureAlt}/>}
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.text}>
                    {handbookInfo?.logo && <Logo logoBase64={handbookInfo?.logo}/>}
                    {handbookInfo?.companies && <p className={styles.subheading}>{handbookInfo?.companies ? handbookInfo.companies[0].name : ""}</p>}
                    <h1 className={classNames(styles.title, styles.separator)}>{handbookInfo?.title}</h1>
                    <LoginForm/>
                </div>
            </div>
        </div>
    )
}

export default LoginPage;