import React, {createContext, useEffect, useState} from "react";
import {useParams} from "react-router";
import {useAuth} from "../auth/useAuth";
import {DigitalHandbookClient, HandbookViewModel} from "./api";
import ApiFactory from "./ApiFactory";
import Spinner from "../components/Spinner/Spinner";

export const dataContext = createContext(undefined as any);

export function ProvideData({children}: { children: JSX.Element }) {
    const [handbook, setHandbook] = useState<HandbookViewModel | undefined>(undefined);
    const {isAuthenticated, signout} = useAuth();
    const {handbookId} = useParams<{ handbookId: string }>();

    useEffect(() => {
        const fetchHandbook = async () => {
            try {
                const handbook = await ApiFactory.create(DigitalHandbookClient).get(handbookId);
                setHandbook(handbook);
            } catch (e) {
                signout();
            }
        }
        if (isAuthenticated) {
            fetchHandbook();
        }
    }, [isAuthenticated, handbookId]);

    return (
        <dataContext.Provider value={handbook}>
            {
                handbook
                    ? children
                    : <Spinner/>
            }
        </dataContext.Provider>
    );
}