import React, {createContext} from "react";
import { retrieveToken, retrieveUserName } from "./jwtAuth";
import { useProvideAuth } from "./useAuth";

export const authContext = createContext(null as any);

export function ProvideAuth({children}: { children: JSX.Element }) {
    const auth = useProvideAuth();
    const token = retrieveToken();
    const username = retrieveUserName();
    auth.isAuthenticated = token != null;
    auth.user = username;
    
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}
