import styles from './Footer.module.css';

function Footer() {
    return (
        <footer className={styles.content}>
            <p>@NHO {new Date().getFullYear()}</p>
        </footer>
    );
}

export default Footer;