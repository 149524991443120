import React, {useEffect, useState} from 'react';
import AccordionItem from './AccordionItem';
import {ChapterViewModel} from '../../../api/api';
import styles from './Accordion.module.css';
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import {useTheme} from "../../../theme/useTheme";

type AccordionProps = {
    chapters: ChapterViewModel[];
}

const Accordion = ({chapters}: AccordionProps) => {
    const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
    const {handbookId, chapterId} = useParams<{ handbookId: string, chapterId: string }>();
    const history = useHistory();
    const {themeColors} = useTheme();

    const onClick = (index: number, link: string, firstSectionLink: string) => {
        history.push(`/handbok/${handbookId}/chapters/${link}#${firstSectionLink}`);

        if (activeIndex === index) {
            setActiveIndex(undefined)
        } else {
            setActiveIndex(index)
        }
    }

    useEffect(() => {
        const currentItemIndex = chapters.findIndex(x => x.softLink === chapterId);
        setActiveIndex(currentItemIndex);
    }, [chapterId])

    return (
        <ul className={styles.content} role="menu">
            {chapters.map((chapter: any, index: number) =>
                <AccordionItem
                    key={`${chapter.softLink}-${index}`}
                    chapter={chapter}
                    isExpanded={index === activeIndex}
                    index={index}
                    onClick={() => onClick(index, chapter.softLink, chapter.sections[0]?.softLink)}
                    themeColors={themeColors}
                />
            )}

        </ul>
    );
};

export default Accordion;