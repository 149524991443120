import {Redirect, Route} from "react-router-dom";
import React from "react";
import {useAuth} from "./useAuth";
import {ProvideData} from "../api/ProvideData";
import {ProvideHandbookInfo} from "../api/ProvideHandbookInfo";

function PrivateRoute({children, ...rest}: { children: JSX.Element, path: string }) {
    let auth = useAuth();
    return (
        <Route
            {...rest}
            exact={true}
            render={({location, match}) => {
                return auth.isAuthenticated ? (
                    <ProvideHandbookInfo>
                        <ProvideData>
                            {children}
                        </ProvideData>
                    </ProvideHandbookInfo>
                ) : (
                    <Redirect
                        to={{
                            pathname: `/handbok/${match.params.handbookId}/login`,
                            search: location.search,
                            state: {from: location, params: match.params || {handbookId: ""}}
                        }}
                    />
                )
            }}/>
    );
}

export default PrivateRoute;