import React, {createContext, useEffect, useState} from "react";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {ThemeName, themeNames, ThemeProps} from "../theme/themeNames";
import i18next from "i18next";
import {languages} from "../translations/languages";
import {getColors} from "../theme/useTheme";
import ApiFactory from "./ApiFactory";
import {HandbookInfoClient, HandbookInfoViewModel} from "./api";
import Spinner from "../components/Spinner/Spinner";
import Head from "../components/Head/Head";

export type HandbookIdContext = {
    theme: ThemeProps | undefined;
    handbookInfo: HandbookInfoViewModel | undefined;
    loadingHandbookInfo: boolean;
}

export const handbookIdContext = createContext({} as HandbookIdContext);

export function ProvideHandbookInfo({children}: { children: JSX.Element }) {
    const [theme, setTheme] = useState<ThemeProps | undefined>(undefined);
    const [loadingHandbookInfo, setLoadingHandbookInfo] = useState(true);
    const [handbookInfo, setHandbookInfo] = useState<HandbookInfoViewModel | undefined>(undefined);
    const history = useHistory();
    const {handbookId} = useParams<{ handbookId: string }>();

    function updateTheme(themeName: string) {
        const themeColors = getColors(themeName as ThemeName);
        setTheme(themeColors);
    }

    function updateLanguage(lang: string) {
        i18next.changeLanguage(lang.toLocaleLowerCase());
    }

    function updateHandbookInfo(handbookInfo: HandbookInfoViewModel) {
        setHandbookInfo(handbookInfo);
        updateLanguage(handbookInfo?.language ?? languages.default);
        updateTheme(handbookInfo?.themeColor ?? 'default')
    }

    function setFallbackHandbookInfo() {
        setTheme(themeNames.default);
        setLoadingHandbookInfo(false);
    }

    useEffect(() => {
        const fetchHandbookInfo = async () => {
            try {
                let info = await ApiFactory.create(HandbookInfoClient).get(handbookId);
                updateHandbookInfo(info);
            } catch (e) {
                history.replace({pathname: "/404", search: "", hash: "", state: undefined});
            }
        };

        if (handbookId) {
            setLoadingHandbookInfo(true);
            fetchHandbookInfo().then(() => setLoadingHandbookInfo(false));
        } else {
            setFallbackHandbookInfo();
            history.replace({pathname: "/404", search: "", hash: "", state: undefined});
        }
    }, [handbookId]);

    return (
        <handbookIdContext.Provider value={{
            theme,
            handbookInfo,
            loadingHandbookInfo,
        }}>
            <Head
                handbookInfo={handbookInfo}
            />
            {
                loadingHandbookInfo
                    ? <Spinner/>
                    : children
            }
        </handbookIdContext.Provider>
    );
}