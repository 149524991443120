import {useParams} from "react-router";
import {Link} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {SectionViewModel} from '../../../api/api';
import {ThemeColors} from "../../../theme/useTheme";
import Placeholder from './Placeholder';
import styles from './ChapterListItem.module.css';

type ChapterListItemProps = {
    heading: string;
    imageAzurePath: string;
    imageAzureAlt: string;
    sections: SectionViewModel[];
    id: string;
    themeColors: ThemeColors;
}

const SectionLink = ({
                         section,
                         id,
                         showSeparator
                     }: { section: SectionViewModel, id: string, showSeparator: boolean }
) => {
    const {handbookId} = useParams<{ handbookId: string }>();
    return (
        <>
            <Link
                to={{
                    pathname: `/handbok/${handbookId}/chapters/${id}`,
                    hash: `${section.softLink}`,
                    state: {chapterId: id}
                }}
            >
                {section.heading}
            </Link>
            {showSeparator && <span>/</span>}
        </>);
}

function ChapterListItem({heading, imageAzurePath, imageAzureAlt, sections, id, themeColors}: ChapterListItemProps) {
    const {t} = useTranslation('common');
    const {handbookId} = useParams<{ handbookId: string }>();
    const list = sections?.map(
        (section, index) =>
            <SectionLink
                key={`${section.softLink}-${index}`}
                section={section}
                id={id}
                showSeparator={sections.length - 1 > index}
            />
    );

    return (
        <li className={styles.content} id={id}>
            <div className={styles.inner}>
                <div className={styles.image}>
                    {imageAzurePath
                        ? <img src={imageAzurePath}
                               alt={imageAzureAlt}
                               loading="lazy"
                        />
                        : <Placeholder
                            colorDark={themeColors.dark}
                            colorLight={themeColors.light}
                        />
                    }
                </div>
                <div className={styles.text}>
                    <div>
                        <h2>
                            <Link
                                to={{
                                    pathname: `/handbok/${handbookId}/chapters/${id}`,
                                    state: {chapterId: id}
                                }}
                            >{heading}</Link>
                        </h2>
                        <p>{list}</p>
                    </div>
                    <Link
                        to={{
                            pathname: `/handbok/${handbookId}/chapters/${id}`,
                            state: {chapterId: id}
                        }}
                        className={styles.button}
                    >
                        {t('home.chaptersList.listItem.readMore')}
                    </Link>
                </div>
            </div>
        </li>
    );
}

export default ChapterListItem;
