import {configureRefreshFetch, fetchJSON} from "refresh-fetch";
import {retrieveToken, shouldRefreshToken, refreshToken} from "../auth/jwtAuth";

const fetchJsonWithToken = async (url: RequestInfo, init?: RequestInit) : Promise<Response> => {

    const token = retrieveToken();

    let optionsWithToken = {};
    if(token) {
        optionsWithToken = {
            headers: {
                Authorization : `Bearer ${token}`
            }
        }
    }

    return Promise.resolve((await fetchJSON(url, optionsWithToken)).response);
};

export default configureRefreshFetch({
    fetch: fetchJsonWithToken,
    shouldRefreshToken: shouldRefreshToken,
    refreshToken: refreshToken
})