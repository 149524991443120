import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {HandbookViewModel, SectionViewModel} from "../../api/api";
import {useLocation} from "react-router-dom";
import SearchResults from "./SearchResults/SearchResults";
import SearchInput from "./SearchInput/SearchInput";
import {useTheme} from "../../theme/useTheme";
import {HeaderType} from "../Layout/Header/Header";
import styles from './Search.module.css';

type SearchProps = {
    handbook: HandbookViewModel
    isOpen: boolean;
    isUp: boolean;
    isSticky: boolean;
    onSearchClose: () => void;
    headerType: HeaderType;
}

function Search({handbook, isUp, isOpen, isSticky, onSearchClose, headerType}: SearchProps) {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResult, setSearchResult] = useState<any>([]);
    const {pathname, hash} = useLocation();
    const {themeColors} = useTheme();

    useEffect(() => {
        setSearchResult([])
        setSearchTerm('')
    }, [isOpen])

    useEffect(() => {
        onSearchClose();
    }, [pathname, hash])

    const onSearch = (searchString: string) => {
        setSearchTerm(searchString);
        let hits: SectionViewModel[] | undefined = [];

        if (searchString.length > 2) {
            hits = handbook && handbook.chapters && handbook.chapters.reduce((acc: SectionViewModel[], chapter) => {
                let sections = chapter.sections?.map(s => {
                    return {
                        ...s,
                        parent: chapter.softLink,
                        parentTitle: chapter.heading
                    };
                })
                let test = sections?.filter(section => section.content?.includes(searchString) || section?.customContent?.includes(searchString)) ?? [];
                acc = [...acc, ...test]
                return acc
            }, []);
        }

        if (hits?.length) {
            setSearchResult(hits)
        } else setSearchResult([])
    }

    return (
        <div
            className={classnames(
                styles.content,
                {
                    [styles.open]: isOpen,
                    [styles.up]: isUp,
                    [styles.isSticky]: isSticky,
                    [styles.expanded]: searchTerm.length > 2
                }
            )}
        >
            {isOpen && <div
                className={styles.inputWrapper}
                style={
                    headerType === HeaderType.CHAPTER
                        ? {backgroundColor: themeColors.light}
                        : {backgroundColor: '#f8f8f8'}
                }
            >
                <div className={styles.inputWrapperInner}>
                    <SearchInput onSearch={onSearch}/>
                </div>
            </div>}
            {isOpen && searchTerm.length > 1 && <div
                className={styles.wrapper}
            >
                <SearchResults
                    searchTerm={searchTerm}
                    isOpen={searchResult.length > 0}
                    searchHits={searchResult}
                />
            </div>}
        </div>
    );
}

export default Search;
