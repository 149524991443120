import {useContext} from "react";
import {ChapterViewModel, HandbookViewModel} from "./api";
import {dataContext} from "./ProvideData";

type UseHandbookData = {
    handbook: HandbookViewModel;
    chapters: ChapterViewModel[];
    companyName: string;
    branchName: string;
}

export function useHandbookData(): UseHandbookData {
    const handbook = useContext<HandbookViewModel>(dataContext) ?? {};

    function getHandbook(): HandbookViewModel {
        return handbook || {};
    }

    function getChapters(): ChapterViewModel[] {
        const chapters = handbook.chapters;
        return chapters ?? [];
    }

    function getCompanyName(): string {
        const companyName = handbook.companies && !handbook.hideCompanyName ? (handbook.companies[0]?.name2 ? handbook.companies[0]?.name1 : handbook.companies[0]?.name) : "";
        return companyName ?? '';
    }

    function getBranchName() : string {
        return handbook.companies && !handbook.hideSecondaryName ? handbook.companies[0]?.name2 ?? '' : '';
    }

    return {
        handbook: getHandbook(),
        chapters: getChapters(),
        companyName: getCompanyName(),
        branchName: getBranchName()
    }
}