import React from "react";
import {useTranslation} from "react-i18next";
import styles from './BackToTop.module.css';

function BackToTop() {
    const {t} = useTranslation('common');

    const onClick = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return (
        <button
            className={styles.content}
            onClick={onClick}
        >
            <span className="visually-hidden">
                {t('chapter.backToTop')}
            </span>
        </button>
    );
}

export default BackToTop;