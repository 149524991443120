export type ThemeName =
    | "orange"
    | "atlantis"
    | "bluelight"
    | "roseblad"
    | "roseleaf"
    | "gray"
    | "default";

export type ThemeProps = {
    dark: string,
    light: string,
    button?: string,
    buttonText?: string
}

export const themeNames: Record<ThemeName, ThemeProps> = {
    orange: {
        dark: "#E6A65D",
        light: "#EDC18E",
        button: "#222222",
        buttonText: "#ffffff"
    },
    roseblad: {
        dark: "#E6A65D",
        light: "#EDC18E",
        button: "#222222",
        buttonText: "#ffffff"
    },
    roseleaf: {
        dark: "#E6A65D",
        light: "#EDC18E",
        button: "#222222",
        buttonText: "#ffffff"
    },
    atlantis: {
        dark: "#9CDBD9",
        light: "#BAE6E4",
    },
    bluelight: {
        dark: "#5BC2E7",
        light: "#8CD4EE",
    },
    gray: {
        dark: "#b5b5b5",
        light: "#D5D3D3",
    },
    default: {
        dark: "#b5b5b5",
        light: "#D5D3D3",
    },
};