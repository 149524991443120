import React, {useContext} from "react";
import {chapterContext} from "../../pages/Chapter/ChapterPage";
import {useChapterData} from "../../api/useChapterData";
import SideMenu from "./SideMenu/SideMenu";
import Section from "./Section/Section";
import Updates from "./Updates/Updates";
import styles from './Chapter.module.css'

type ChapterProps = {
    chapterId: string;
}

function Chapter({chapterId}: ChapterProps) {
    const refs = useContext(chapterContext);
    const {sections} = useChapterData(chapterId);

    return (
        <main className={styles.content}>
            <div className={styles.menu}>
                <SideMenu/>
            </div>
            <div className={styles.text}
                 id="main"
                 aria-live="polite"
                 aria-atomic="false"
                 aria-relevant="text"
            >
                {sections.map((section, index) => <Section
                    key={`${section.softLink}-${index}`}
                    section={section}
                    myRef={(el: any) => refs.current[index] = el}
                />)}
            </div>
        </main>
    );
}

export default Chapter;