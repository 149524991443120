import React, {useContext} from "react";
import {SectionViewModel} from '../../../api/api';
import {handbookIdContext} from "../../../api/ProvideHandbookInfo";
import SectionLinks from "./SectionLinks";
import SectionTariffAgreements from "./SectionTariffAgreements";
import {useTranslation} from "react-i18next";
import styles from './Section.module.css';

type SectionProps = {
    section: SectionViewModel
    myRef?: any;
}

function SectionContent({section}: { section: SectionViewModel }) {
    const {handbookInfo} = useContext(handbookIdContext);
    const organization = handbookInfo?.companies && handbookInfo.companies[0];
    const anyCompanyHasAgreements = organization?.companies?.some(company => company.agreements?.length! > 0)
    const {t} = useTranslation('common');

    function createMarkup() {
        return {__html: `${section.content || ''}\n${section.customContent || ''}`};
    }

    return (<>
        <h2>{section.heading}</h2>
        <div dangerouslySetInnerHTML={createMarkup()}/>

        {section.linkLists?.length && section.linkLists.length > 0 
            ? section.linkLists.filter(link => link.links && link.links?.length > 0).map(link => <div key={link.id}>
                    {link.heading && <h3>{link.heading}</h3>}
                    <p> 
                        {link.links?.map((linkItem, index) =>
                            <SectionLinks
                                title={linkItem.title ?? ''}
                                url={linkItem.url ?? ''}
                                key={`${link.id}-${index}`}
                            />
                        )}
                    </p>
                </div>
            )
            : null
        }

        {section.azureFilesList?.length && section.azureFilesList.length > 0
            ? <div>
                <h3>{t('section.documents')}</h3>
                <p>
                    {section.azureFilesList.map((file, index) => <>
                            <SectionLinks
                                title={file.title ?? ''}
                                url={file.url ?? ''}
                                key={`fileLink-${index}`}
                                isDocument={true}
                            />
                        </>
                    )}
                </p>
            </div>
            : null
        }

        {anyCompanyHasAgreements && section.showCollectiveAgreements && organization && <SectionTariffAgreements
            companies={organization.companies}
        />}</>);
}

function Section({section, myRef}: SectionProps) {
    return (
        <div className={styles.content} id={section.softLink} ref={myRef}>
            <SectionContent section={section} key={`main-${section.softLink}`}/>
            {section.sections && section.sections.map((section, index) => {
                return (<section key={`sub-${index}`}>
                    <SectionContent section={section} key={`sub-${section.softLink}-${index}`}/>
                </section>);
            })}
        </div>
    );
}

export default Section;