import {useParams} from "react-router";
import {Link} from "react-router-dom";
import {useChapterData} from "../../../api/useChapterData";
import styles from './BottomNav.module.css';

function BottomNav() {
    const {handbookId} = useParams<{ handbookId: string }>();
    const {chapterId} = useParams<{ chapterId: string }>();
    const {next, previous} = useChapterData(chapterId);

    return (
        <div className={styles.content}>
            <div className={styles.wrapper}>
                <div className={styles.column}>
                    {previous && previous.link && <Link
                        to={`/handbok/${handbookId}/chapters/${previous.link}`}
                        className={styles.prev}
                    >
                        {previous.title}
                    </Link>}
                </div>
                <div className={styles.column}>
                    {next && next.link && <Link
                        to={`/handbok/${handbookId}/chapters/${next.link}`}
                        className={styles.next}
                    >
                        {next.title}
                    </Link>}
                </div>
            </div>
        </div>
    );
}

export default BottomNav;