import React from "react";
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import {ChapterViewModel} from "../../../api/api";
import {ThemeColors} from "../../../theme/useTheme";
import AccordionSubItem from './AccordionSubItem';
import {useParams} from "react-router";
import styles from './AccordionItem.module.css';

type AccordionItemProps = {
    chapter: ChapterViewModel;
    isExpanded: boolean;
    index: number;
    onClick: () => void;
    themeColors: ThemeColors;
}

function AccordionItem({chapter, isExpanded, index, onClick, themeColors}: AccordionItemProps) {
    const {chapterId} = useParams<any>();
    return (
        <li className={styles.content} role="none">
            <dt role="none">
                <button
                    aria-expanded={isExpanded}
                    aria-controls={`side-menu-${index + 1}`}
                    onClick={onClick}
                    className={classnames(
                        styles.button,
                        styles.down,
                        {[styles.up]: isExpanded},
                        {[styles.current]: chapter.softLink === chapterId}
                    )}
                    role="menuitem"
                    id={`side-menu-button-${index + 1}`}
                >
                    {chapter.heading}
                </button>
            </dt>
            <dd className={styles.description} role="none">
                <AnimateHeight
                    duration={300}
                    height={isExpanded ? 'auto' : 0}
                >
                    <ul
                        className={styles.submenu}
                        id={`side-menu-${index + 1}`}
                        aria-labelledby={`side-menu-button-${index + 1}`}
                        role="menu"
                    >
                        {chapter.sections?.map((section, index) =>
                            <AccordionSubItem
                                key={`${section.softLink}-${index}`}
                                chapterId={chapter.softLink}
                                section={section}
                                themeColors={themeColors}
                            />)}
                    </ul>
                </AnimateHeight>
            </dd>
        </li>
    )
};

export default AccordionItem;