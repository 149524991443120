import React, {useState} from "react";
import styles from './Section.module.css';
import {useTranslation} from "react-i18next";

type SectionTariffAgreementsProps = {
    companies: any;
}

function SectionTariffAgreements({companies}: SectionTariffAgreementsProps) {
    const [selectedCompany, setSelectedCompany] = useState<any | undefined>();
    const {t} = useTranslation('common');

    function onChange(e: any) {
        let company = companies.find((company: any) => company.id == e.target.value);
        setSelectedCompany(company);
    }

    return (
        <div>
            <h3>{t('section.tariffAgreements.heading')}</h3>
            <select onChange={onChange}>
                <option value="" disabled selected hidden>{t('section.tariffAgreements.selectPlaceholder')}</option>
                {
                    companies.map((company: any) => <option
                        value={company.id}
                        key={company.id}
                    >
                        {company.name}
                    </option>)
                }
            </select>
            <p>
                {selectedCompany?.agreements && selectedCompany?.agreements.length > 0 ? (selectedCompany.agreements.map((agreement: any) =>
                    <a
                        key={agreement.url}
                        className={styles.agreementLink}
                        href={agreement.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {agreement.title}
                    </a>
                )) : t('section.tariffAgreements.noAgreementsFound')}
            </p>
        </div>
    );
}

export default SectionTariffAgreements;