import classnames from 'classnames';
import {useTranslation} from "react-i18next";
import {useState} from 'react';
import styles from './Hamburger.module.css';

type HamburgerProps = {
    isOpen: boolean
    onClick: () => void
}

function Hamburger({isOpen, onClick}: HamburgerProps) {
    const [animated, setAnimated] = useState(false);
    const {t} = useTranslation('common');

    const onToggle = () => {
        setAnimated(true)
        onClick()
    }

    return (
        <button
            id="menu-button-1"
            aria-expanded={isOpen}
            className={styles.content}
            onClick={onToggle}
        >
            <span>
                {t('menu.info')}
            </span>
            <svg width="20" height="20" viewBox="0 0 20 20"
                 className={classnames(styles.svg, {
                     [styles.open]: isOpen,
                     [styles.close]: !isOpen && animated,
                 })}
                 stroke="hsl(0, 0%, 18%)"
                 aria-hidden="true"
            >
                <path
                    fill="transparent"
                    strokeWidth="1.7"
                    stroke="hsl(0, 0%, 18%)"
                    strokeLinecap="round"
                    d={'M 0 4 L 20 4'}
                    style={{transition: 'all .3s ease'}}
                />
                <path
                    fill="transparent"
                    strokeWidth="1.7"
                    stroke="hsl(0, 0%, 18%)"
                    strokeLinecap="round"
                    d={'M 0 10 L 20 10'}
                />
                <path
                    fill="transparent"
                    strokeWidth="1.7"
                    stroke="hsl(0, 0%, 18%)"
                    strokeLinecap="round"
                    d={'M 0 16 L 20 16'}
                    style={{transition: 'all .3s ease'}}
                />
            </svg>
        </button>
    );
}

export default Hamburger;
