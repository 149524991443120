type PlaceholderProps = {
    colorLight: string
    colorDark: string
    angle?: number
}

function Placeholder({colorLight, colorDark, angle = 30}: PlaceholderProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" preserveAspectRatio="xMidYMin slice">
            <path fill={colorLight} d="M10.19,100,69.491,0H100V100Z"/>
            <path fill={colorDark} d="M0,0H55.19q22.351,40.64,44.7,81.28S100,93.72,100,100H0Z"/>
        </svg>
    );
}

export default Placeholder;
