import React from "react";
import {Route} from "react-router-dom";
import {ProvideHandbookInfo} from "../api/ProvideHandbookInfo";

function PublicRoute({children, ...rest}: { children: JSX.Element, path: string, exact?: boolean }) {
    return (
        <Route
            {...rest}
            render={() => {
                return (
                    <ProvideHandbookInfo>
                        {children}
                    </ProvideHandbookInfo>
                )
            }}/>
    );
}

export default PublicRoute;