import React from 'react';
import Placeholder from '../ListItem/Placeholder';
import {useTheme} from "../../../theme/useTheme";
import styles from './Banner.module.css';

type BannerProps = {
    heading?: string;
    imagePath?: string;
    imageAlt?: string;
}

function Banner({heading, imagePath, imageAlt}: BannerProps) {
    const {themeColors} = useTheme();

    return (
        <div className={styles.content}>
            <div className={styles.grid}>
                <div className={styles.text}>
                    <div className={styles.container}>
                        <h1 className={styles.heading}>{heading}</h1>
                    </div>
                </div>
                <div className={styles.media}>
                    {imagePath && <div className={styles.img}>
                        <img 
                            src={imagePath} 
                            alt={imageAlt} 
                            loading="lazy"
                        />
                    </div>}
                </div>
            </div>
            {!imagePath && <div className={styles.trapezoid}>
                <Placeholder
                    colorDark={"white"}
                    colorLight={themeColors.dark}
                    angle={20}
                />
            </div>}
        </div>
    );
}

export default Banner;
