import React, {createContext, useContext, useState} from 'react';
import {useParams} from "react-router";
import {useHistory} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {HandbookViewModel} from "../../../api/api";
import {useTheme} from "../../../theme/useTheme";
import useSticky from "./useSticky";
import useIsMobile from "../../../helpers/useIsMobile";
import Hamburger from './Hamburger/Hamburger';
import Logo from './Logo/Logo';
import Nav from './Nav/Nav';
import classnames from 'classnames';
import Button from '../../Button/Button';
import Search from "../../Search/Search";
import HeaderButton from "./HeaderButton/HeaderButton";
import Account from "./Account/Account";
import classNames from "classnames";
import Settings from "./Account/Settings";
import {handbookIdContext} from "../../../api/ProvideHandbookInfo";
import styles from './Header.module.css';
import ButtonStyles from './../../Button/Button.module.css';

export enum HeaderType {
    CHAPTER = 'chapter',
    HANDBOOK = 'handbook'
}

type HeaderProps = {
    handbook: HandbookViewModel;
    headerType: HeaderType;
    ref?: any;
    logoBase64?: string | undefined;
}

export const searchContext = createContext(undefined as any);

function Header({handbook, headerType, logoBase64}: HeaderProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isSearchTermEmpty, setIsSearchTermEmpty] = useState(false);
    const history = useHistory();
    const {handbookId} = useParams<{ handbookId: string }>();
    const {themeColors} = useTheme();
    const {isSticky, isUp} = useSticky();
    const {t} = useTranslation('common');
    const {isMobile} = useIsMobile();
    const {handbookInfo} = useContext(handbookIdContext);

    const onMenuOpen = () => {
        onSearchClose();
        onSettingsClose();
        document.body.style.position = 'fixed';
        document.body.style.overflowY = 'scroll';
        setIsMenuOpen(true);
    };

    const onMenuClose = () => {
        document.body.style.position = `static`;
        document.body.style.overflowY = 'auto';
        setIsMenuOpen(false);
    };

    const onSettingsOpen = () => {
        onSearchClose();
        onSettingsClose();
        setIsSettingsOpen(true);
    };

    const onSettingsClose = () => {
        onSearchClose();
        onMenuClose();
        setIsSettingsOpen(false);
    };

    const onSearchOpen = () => {
        onMenuClose();
        onSettingsClose();
        setIsSearchOpen(true);
    };

    const onSearchClose = () => {
        document.body.style.position = `static`;
        document.body.style.overflowY = 'auto';
        setIsSearchOpen(false);
    };

    const onClick = () => {
        history.push(`/handbok/${handbookId}`);
    }

    return (
        <searchContext.Provider value={{isSearchOpen, onSearchClose, isSearchTermEmpty, setIsSearchTermEmpty}}>
            <header
                className={classnames(styles.content,
                    {
                        [styles.chapter]: headerType === HeaderType.CHAPTER,
                        [styles.handbook]: headerType === HeaderType.HANDBOOK,
                        [styles.sticky]: isSticky,
                        [styles.up]: isUp,
                    })
                }
            >
                <div
                    className={classnames(styles.background,
                        {
                            [styles.chapter]: headerType === HeaderType.CHAPTER,
                            [styles.handbook]: headerType === HeaderType.HANDBOOK,
                            [styles.open]: isMenuOpen
                        })
                    }
                    style={{backgroundColor: headerType === HeaderType.CHAPTER ? themeColors.light : "#f8f8f8"}}
                >
                    <div className={styles.wrapper}>
                        <div className={styles.inner}>
                            <a
                                className="skipToMainContent"
                                href="#main"
                            >
                                {t('header.skipToMainContent')}
                            </a>

                            {headerType === HeaderType.HANDBOOK && logoBase64 && <Logo logoBase64={logoBase64}/>}
                            {headerType === HeaderType.CHAPTER &&
                            <Button
                                color={'white'}
                                text={t('header.backToHomePage')}
                                onClick={onClick}
                                cssClass={ButtonStyles.back}
                            />}
                            <div className={styles.items}>
                                
                                <div className={styles.item}>
                                    <HeaderButton
                                        id={'search-input-button-1'}
                                        text={isSearchOpen
                                            ? t('search.close')
                                            : t('search.buttonText')
                                        }
                                        isOpen={isSearchOpen}
                                        cssClass={isSearchOpen ? 'close' : 'search'}
                                        onClick={isSearchOpen ? onSearchClose : onSearchOpen}
                                    />
                                </div>
                                {!isSearchOpen && <div className={styles.item}>
                                    <Hamburger
                                        isOpen={isMenuOpen}
                                        onClick={isMenuOpen ? onMenuClose : onMenuOpen}
                                    />
                                </div>}
                                {!isMobile && <div className={styles.item}>
                                    <Account
                                        isOpen={isSettingsOpen}
                                        onClick={isSettingsOpen ? onSettingsClose : onSettingsOpen}
                                    />
                                    <Settings
                                        isOpen={isSettingsOpen}
                                        onLogoutClick={onSettingsClose}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <Search
                    handbook={handbook}
                    isOpen={isSearchOpen}
                    isUp={isUp}
                    isSticky={isSticky}
                    onSearchClose={onSearchClose}
                    headerType={headerType}
                />
                <Nav
                    handbook={handbook}
                    isOpen={isMenuOpen}
                    onMenuClose={onMenuClose}
                />
            </header>
        </searchContext.Provider>
    );
}

export default Header;
