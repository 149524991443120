import React from 'react';
import classNames from "classnames";
import styles from './HeaderButton.module.css';

type HeaderButtonProps = {
    text: string;
    isOpen: boolean;
    id: string;
    cssClass: string;
    onClick: () => void;
}

function HeaderButton({text, isOpen, id, cssClass, onClick}: HeaderButtonProps) {
    return (
        <button
            id={id}
            className={classNames(
                styles.content,
                styles[cssClass]
            )}
            aria-expanded={isOpen}
            onClick={onClick}
        >
            <span className={styles.text}>
                {text}
            </span>
        </button>
    );
}

export default HeaderButton;