export function debounce<F extends Function>(func: F, wait: number = 0): F {
    let timeoutID: number;

    if (!Number.isInteger(wait)) {
        wait = 300;
    }

    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutID);
        const context = this;

        timeoutID = window.setTimeout(function () {
            func.apply(context, args);
        }, wait);
    } as any;
};