import {ChapterViewModel, HandbookViewModel, SectionViewModel} from "./api";
import {useContext} from "react";
import {dataContext} from "./ProvideData";

export type nextPrevChapter = { link: string, title: string };

type UseChapterData = {
    previous?: nextPrevChapter;
    next?: nextPrevChapter;
    chapter: ChapterViewModel;
    sections: SectionViewModel[];
}

export function useChapterData(chapterId: string): UseChapterData {
    const handbook = useContext<HandbookViewModel>(dataContext) ?? {};
    const chapters = handbook.chapters ?? [];
    const index = chapters.findIndex(x => x.softLink === chapterId);
    const chapter = chapters[index];

    function getChapter() {
        return chapter || {};
    }

    function getSections() {
        const sections = chapter?.sections;
        return sections || [];
    }

    function getChapterLink(targetIndex: number) {
        const targetChapter = chapters[targetIndex];
        if (!targetChapter) return undefined;
        return {
            link: targetChapter.softLink || '', title: targetChapter.heading || ''
        };
    }

    return {
        previous: getChapterLink(index - 1),
        next: getChapterLink(index + 1),
        chapter: getChapter(),
        sections: getSections()
    };
}