import React from 'react';
import {useTheme} from "../../theme/useTheme";
import styles from './Hero.module.css';
import {useHandbookData} from '../../api/useHandbookData';

type HeroProps = {
    title?: string;
    coverPhotoPath?: string;
    coverPhotoAlt?: string;
    companyName: string;
    branchName: string;
}

function Hero({title, coverPhotoPath, coverPhotoAlt, companyName, branchName}: HeroProps) {
    const {themeColors} = useTheme();
    const {handbook} = useHandbookData();

    return (
        <div className={styles.content}>
            <div className={styles.text}>
                <div className={styles.container}>
                    <h2 className={styles.subheading}>{companyName}{handbook?.isDemo && <span className={styles.demo}>Demo</span>}</h2>
                    <h2 className={styles.subheading}>{branchName}</h2>
                    <h1 className={styles.heading}>{title}</h1>
                </div>
            </div>
            <div className={styles.background} style={{backgroundColor: themeColors.dark}}></div>
            <div className={styles.media} style={{backgroundColor: themeColors.light}}>
                {coverPhotoPath && <img src={coverPhotoPath} alt={coverPhotoAlt}/>}
            </div>
        </div>
    );
}

export default Hero;
