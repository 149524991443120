import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import {languages} from "./translations/languages";
import common_en from "./translations/en/common.json";
import common_no from "./translations/no/common.json";
import './static/styles/index.css';

i18next
    .init({
        detection: {
            htmlTag: document.documentElement,
        },
        interpolation: {escapeValue: false},
        lng: languages.default,
        resources: {
            en: {
                common: common_en
            },
            no: {
                common: common_no
            },
        },
    });

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <App/>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
